import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Header from "./components/header/header";
import Banner from "./components/banner/banner";
import Benefits from "./components/benefits/benefits";
import Layouts from "./components/layouts/layouts";
import Infrastructure from "./components/infrastructure/infrastructure";
import Boulevard from "./components/boulevard/boulevard";
import Gallery from "./components/gallery/gallery";
import Purchase from "./components/purchase/purchase";
import Panorama from "./components/panorama/panorama";
import Form from "./components/form/form";
import Progress from "./components/progress/progress";
import Footer from "./components/footer/footer";
import { AppContext } from "./context/context";
import Success from "./components/success/success";
import PageNotFround from "./components/pageNotFound/pageNotFround";
// import Snowfall from "react-snowfall";

function App() {
    const [is404, setIs404] = useState(false);

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath !== "/") {
            setIs404(true);
        }
    }, [window.location.pathname]);

    const { successPage, toggleSuccessPage } = useContext(AppContext);
    if (is404) {
        return <PageNotFround />;
    }
    if (successPage) {
        return <Success toggleSuccessPage={toggleSuccessPage} />;
    }
    return (
        <React.Fragment>
            {/* <Snowfall color="white" style={{ zIndex: 30, position: "fixed", top: 0 }} snowflakeCount={40} speed={[0.5, 1]} wind={[-0.5, 2]} radius={[0.5, 2.5]} /> */}
            <Header />
            <Banner />
            <Benefits />
            <Layouts />
            <Infrastructure />
            <Boulevard />
            <Gallery />
            <Purchase />
            <Panorama />
            <Progress />
            <Form />
            <Footer />
        </React.Fragment>
    );
}

export default App;
